<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <path
      d="M36.729,8.515a8.192,8.192,0,0,1,6.078,2.643,8.539,8.539,0,0,1,2.6,6.167c0,2.312-1.411,4.955-4.233,8.149L25.876,41.99l-15.3-16.518c-2.822-3.193-4.233-5.836-4.233-8.149a8.539,8.539,0,0,1,2.6-6.167,8.192,8.192,0,0,1,6.078-2.643c2.388,0,5.535,2.2,7.054,4.955L24.9,18.315a1.184,1.184,0,0,0,1.953,0l2.822-4.845c1.519-2.753,4.667-4.955,7.054-4.955m0-4.515C32.5,4,28.047,7.414,25.876,11.158,23.706,7.414,19.256,4,15.023,4A12.932,12.932,0,0,0,5.8,7.964,13.2,13.2,0,0,0,2,17.324c0,4.515,2.93,8.369,5.426,11.232l17.69,19.16a1.074,1.074,0,0,0,1.519,0l17.69-19.16c2.5-2.863,5.426-6.717,5.426-11.232A13.231,13.231,0,0,0,36.728,4Z"
      transform="translate(-1.846 -1.76)"
      fill="currentColor"
    />
  </svg>
</template>
